<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {
  BanknotesIcon,
  ChartBarSquareIcon,
  CogIcon,
  Bars3Icon,
  XMarkIcon,
  QuestionMarkCircleIcon,
  InformationCircleIcon,
  TvIcon,
  ArrowLeftCircleIcon,
  WalletIcon,
  CurrencyEuroIcon,
} from '@heroicons/vue/24/outline'
import { version as projectVersion } from '~/package.json'

const { addErrorNotification } = useNotifications()

const { auth } = useSupabaseClient()
const user = useSupabaseUser()
const { $Sentry } = useNuxtApp()
const router = useRouter()
const { userCurrencyCode, isUserCurrencyCodeGlobal } = useCurrencyCodes()

// Retrieve user infos and accounts (portfolios)
const { data: userInfos } = await useUserInfos()
const { data: userAccounts } = await useLazyUserAccounts()

// Reactive variables for the quick options section
const isRounding = useRouding()
const { shouldAddUserCountryTax, userTaxCountryCode, userTaxCustom } = await useUserCountryTax(userInfos)

// Navigation links
type NavigationRoute = {
  name: string
  href: string
  icon: typeof BanknotesIcon | VNode
  current: boolean
}

const navigationPartOne: NavigationRoute[] = reactive([
  {
    name: 'Screener',
    href: '/screener',
    icon: TvIcon,
    current: false,
  },
  {
    name: 'Benchmark',
    href: '/benchmark',
    icon: h('svg', {
      xmlns: 'http://www.w3.org/2000/svg',
      width: '24',
      height: '24',
      viewBox: '0 0 24 24',
      fill: 'none',
      stroke: 'currentColor',
      'stroke-linecap': 'round',
      'stroke-linejoin': 'round',
      class: 'stroke-[1.5px]',
      }, [
        h('rect', {
          width: '8',
          height: '4',
          x: '8',
          y: '2',
          rx: '1',
          ry: '1'
        }),
        h('path', {
          d: 'M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2'
        }),
        h('path', {
          d: 'M12 11h4'
        }),
        h('path', {
          d: 'M12 16h4'
        }),
        h('path', { d: 'M8 11h.01' }),
        h('path', { d: 'M8 16h.01' })
      ]
    ),
    current: false,
  },
  {
    name: 'Achat',
    href: '/purchase',
    icon: CurrencyEuroIcon,
    current: false,
  },
])
const navigationPartTwo: NavigationRoute[] = reactive([
  {
    name: 'Portefeuille',
    href: '/portfolio',
    icon: WalletIcon,
    current: false,
  },
  {
    name: 'Dividendes',
    href: '/dividends',
    icon: BanknotesIcon,
    current: false,
  },
  {
    name: 'Valeur',
    href: '/value',
    icon: ChartBarSquareIcon,
    current: false,
  },
  // {
  //   name: 'Calculateur',
  //   href: '/tools/calculator',
  //   icon: CalculatorIcon,
  //   current: false,
  // },
  // {
  //   name: 'Paramètres',
  //   href: '/settings/import-data',
  //   icon: CogIcon,
  //   current: false,
  // },
])
const currentNavigationRoute = computed<NavigationRoute | undefined>(() => navigationPartTwo.find(route => route.current))

const isSidebarOpen = ref<boolean>(false)
const isSidebarSmall = ref<boolean>(false)

// Watching the route to update the current navigation link
watch(router.currentRoute, () => {
  const route = router.currentRoute
  navigationPartOne.forEach(navRoute => {
    navRoute.current = navRoute.href.split('/')[1] === route.value.path.split('/')[1]
  })
  navigationPartTwo.forEach(navRoute => {
    navRoute.current = navRoute.href.split('/')[1] === route.value.path.split('/')[1]
  })
}, { immediate: true })

// Logout the user
async function logout(): Promise<void> {
  // Loging out the user
  try {
    await auth.signOut()
    $Sentry.setUser(null)
  } catch (e: any) {
    console.error(e)
    $Sentry.captureException(e)
    addErrorNotification({
      message: 'La déconnexion à échouée pour une raison inconnue'
    })
    return
  }

  // Redirecting the user to the login page (by reloading the webapp to clear the cache data)
  window.location = '/login?logout=1' as unknown as Location
}
</script>

<template>
  <div>
    <TransitionRoot as="template" :show="isSidebarOpen">
      <Dialog as="div" class="relative z-40 md:hidden" @close="isSidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 flex z-40">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative flex-1 flex flex-col max-w-xs w-full bg-sky-blue-700">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                  <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="isSidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div class="flex-shrink-0 flex items-center px-4 gap-3">
                  <Logo class="h-8 w-auto" />
                  <h1 class="text-2xl font-semibold text-gray-100">
                    AppTrident
                    <small class="text-xs text-gray-300">{{ `v${projectVersion}` }}</small>
                  </h1>
                </div>
                <nav class="mt-5 px-2 space-y-1">
                  <p class="flex items-center space-x-0 !mt-4 !mb-2 font-semibold text-sm text-sky-blue-200">
                    <svg class="h-5" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256"><path fill="currentColor" d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24m0 192a88 88 0 1 1 88-88a88.1 88.1 0 0 1-88 88m12-136v96a8 8 0 0 1-16 0V95l-11.56 7.71a8 8 0 1 1-8.88-13.32l24-16A8 8 0 0 1 140 80"/></svg>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" d="M16 10v12zm1-1h-5v2h3v10h-3v2h8v-2h-3z"/></svg> -->
                    <span>Sélection</span>
                  </p>
                  <NuxtLink v-for="item in navigationPartOne" :key="item.name" :to="item.href" @click="(isSidebarOpen = false)" :class="[item.current ? 'bg-sky-blue-800 text-white' : 'text-white hover:bg-sky-blue-600 hover:bg-opacity-75', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
                    <component :is="item.icon" class="mr-4 flex-shrink-0 h-6 w-6 text-sky-blue-300" aria-hidden="true" />
                    {{ item.name }}
                  </NuxtLink>
                  <p class="flex items-center space-x-0 !mt-4 !mb-2 font-semibold text-sm text-sky-blue-200">
                    <svg class="h-5" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256"><path fill="currentColor" d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24m0 192a88 88 0 1 1 88-88a88.1 88.1 0 0 1-88 88m25.56-92.74L120 168h32a8 8 0 0 1 0 16h-48a8 8 0 0 1-6.4-12.8l43.17-57.56a16 16 0 1 0-27.86-15a8 8 0 0 1-15.09-5.34a32 32 0 1 1 55.74 29.93Z"/></svg>              <!-- <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" d="M16 10v12zm1-1h-5v2h3v10h-3v2h8v-2h-3z"/></svg> -->
                    <span>Suivi</span>
                  </p>
                  <NuxtLink v-for="item in navigationPartTwo" :key="item.name" :to="item.href" @click="(isSidebarOpen = false)" :class="[item.current ? 'bg-sky-blue-800 text-white' : 'text-white hover:bg-sky-blue-600 hover:bg-opacity-75', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
                    <component :is="item.icon" class="mr-4 flex-shrink-0 h-6 w-6 text-sky-blue-300" aria-hidden="true" />
                    {{ item.name }}
                  </NuxtLink>
                  <div v-if="router.currentRoute.value.meta?.showQuickOptions" class="!mt-6 px-2 space-y-3">
                    <div class="text-xs font-semibold text-indigo-200">Options rapides</div>
                    <div v-if="router.currentRoute.value.meta?.showQuickOptions?.rounding === true">
                      <Toggle
                        v-model="isRounding"
                        is-short
                        class="[&>span>span]:text-gray-200">
                        <template #text>
                          Arrondir aux entiers
                        </template>
                      </Toggle>
                    </div>
                    <div v-if="router.currentRoute.value.meta?.showQuickOptions?.currencyConversion === true">
                      <Toggle
                        v-model="isUserCurrencyCodeGlobal"
                        is-short
                        class="[&>span>span]:text-gray-200">
                        <template #text>
                          Convertir en {{ convertCurrencyCodeToSymbol(userCurrencyCode) }}                    
                          <Tooltip class="ml-1 align-text-bottom">
                            <template #reference>
                              <InformationCircleIcon class="inline-block h-4 w-4 text-gray-200" aria-hidden="true" />
                            </template>
                            <template #floating>
                              Le taux de change utilisé est celui du jour de réception de la transaction (dividende, dépôt, etc).<br/> Vous pouvez modifier cette devise principale dans la rubrique 'Mon compte' de la page Paramètres.
                            </template>
                          </Tooltip>
                        </template>
                      </Toggle>
                    </div>
                    <div v-if="router.currentRoute.value.meta?.showQuickOptions?.taxes === true">
                      <Toggle
                        v-model="shouldAddUserCountryTax"
                        is-short
                        class="[&>span>span]:text-gray-200">
                        <template #text>
                          Estimer impôts                  
                          <Tooltip class="ml-1 align-text-bottom">
                            <template #reference>
                              <InformationCircleIcon class="inline-block h-4 w-4 text-gray-200" aria-hidden="true" />
                            </template>
                            <template #floating>
                              Imposition modifiable dans la rubrique 'Mon compte' de la page Paramètres.<br/>
                              Actuellement: {{
                                userTaxCountryCode === 'FR'
                                ? "Taux d'imposition total fixé à 30% (flat tax française), sauf si votre impôt à la source dépasse 15%, auquel cas la France vous demandera 15% d'impôts en plus."
                                : `Le minimum d'impôts locaux est de ${userTaxCustom?.min_country}%, et le minimum d'impôts totaux (source + local) est de ${userTaxCustom?.min_total}%.`
                              }}
                            </template>
                          </Tooltip>
                        </template>
                      </Toggle>
                    </div>
                  </div>
                </nav>
              </div>
              <Button
                class="m-4 px-2 text-sm border !border-sky-blue-600 text-sky-blue-300 !bg-sky-blue-700 hover:!bg-sky-blue-600"
                data-feedback-fish
                :data-feedback-fish-userid="userInfos?.email || 'unknown'"
                >
                <QuestionMarkCircleIcon class="h-8 w-8 pr-2" aria-hidden="true" />
                Question ou problème ?
              </Button>
              <div class="flex-shrink-0 flex border-t border-sky-blue-800 p-4">
                <div @click="navigateTo('/settings/import-data'); isSidebarOpen = false" class="flex-shrink-0 group block cursor-pointer">
                  <div class="flex items-center">
                    <div>
                      <CogIcon class="inline-block h-6 w-6 text-sky-blue-300" />
                    </div>
                    <div class="ml-3">
                      <p class="text-sm font-medium text-white">Paramètres</p>
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
          <div class="flex-shrink-0 w-14" aria-hidden="true">
            <!-- Force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div :class="isSidebarSmall ? 'md:w-14' : 'md:w-64'" class="hidden md:flex md:flex-col md:fixed md:inset-y-0 z-30 transition-all duration-200">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex-1 flex flex-col min-h-0 bg-sky-blue-700">
        <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto overflow-x-hidden">
          <div class="flex items-center flex-shrink-0 px-2 gap-3">
            <Logo :class="isSidebarSmall ? 'hidden' : ''" class="pl-2 h-10 w-auto" />
            <h1 :class="isSidebarSmall ? 'hidden' : ''" class="text-xl font-semibold text-gray-100 leading-5">
              AppTrident
              <small class="text-xs text-gray-300">{{ `v${projectVersion}` }}</small>
            </h1>
            <button @click="isSidebarSmall = !isSidebarSmall" type="button" class="p-2 inline-flex items-center justify-center rounded-md text-gray-100 hover:text-gray-300">
              <ArrowLeftCircleIcon :class="isSidebarSmall ? 'rotate-180': ''" class="h-6 w-6 transition-transform duration-200" aria-hidden="true" />
            </button>
          </div>
          <nav class="mt-5 flex-1 px-2 space-y-1">
            <p class="flex items-center space-x-0 !mt-4 !mb-2 pl-1 font-semibold text-sm text-sky-blue-200">
              <svg class="h-5" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256"><path fill="currentColor" d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24m0 192a88 88 0 1 1 88-88a88.1 88.1 0 0 1-88 88m12-136v96a8 8 0 0 1-16 0V95l-11.56 7.71a8 8 0 1 1-8.88-13.32l24-16A8 8 0 0 1 140 80"/></svg>
              <span :class="isSidebarSmall ? 'hidden' : ''" >Sélection</span>
            </p>
            <NuxtLink v-for="item in navigationPartOne" :key="item.name" :href="item.href" :class="[item.current ? 'bg-sky-blue-800 text-white' : 'text-white hover:bg-sky-blue-600 hover:bg-opacity-75', 'group flex gap-x-3 items-center px-2 py-2 text-sm font-medium rounded-md']">
              <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-sky-blue-300" aria-hidden="true" />
              <span :class="isSidebarSmall ? 'hidden' : ''">{{ item.name }}</span>
            </NuxtLink>
            <p class="flex items-center space-x-0 !mt-4 !mb-2 pl-1 font-semibold text-sm text-sky-blue-200">
              <svg class="h-5" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256"><path fill="currentColor" d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24m0 192a88 88 0 1 1 88-88a88.1 88.1 0 0 1-88 88m25.56-92.74L120 168h32a8 8 0 0 1 0 16h-48a8 8 0 0 1-6.4-12.8l43.17-57.56a16 16 0 1 0-27.86-15a8 8 0 0 1-15.09-5.34a32 32 0 1 1 55.74 29.93Z"/></svg>              <!-- <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" d="M16 10v12zm1-1h-5v2h3v10h-3v2h8v-2h-3z"/></svg> -->
              <span :class="isSidebarSmall ? 'hidden' : ''">Suivi</span>
            </p>
            <NuxtLink v-for="item in navigationPartTwo" :key="item.name" :href="item.href" :class="[item.current ? 'bg-sky-blue-800 text-white' : 'text-white hover:bg-sky-blue-600 hover:bg-opacity-75', 'group flex gap-x-3 items-center px-2 py-2 text-sm font-medium rounded-md']" :aria-current="item.current ? 'page' : undefined">
              <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-sky-blue-300" aria-hidden="true" />
              <span :class="isSidebarSmall ? 'hidden' : ''">{{ item.name }}</span>
            </NuxtLink>
            <div v-if="!isSidebarSmall && router.currentRoute.value.meta?.showQuickOptions !== undefined" class="!mt-6 px-2 space-y-3">
              <div class="text-xs font-semibold text-indigo-200">Options rapides</div>
              <div v-if="router.currentRoute.value.meta?.showQuickOptions?.rounding === true">
                <Toggle
                  v-model="isRounding"
                  is-short
                  class="[&>span>span]:text-gray-200">
                  <template #text>
                    Arrondir aux entiers
                  </template>
                </Toggle>
              </div>
              <div v-if="router.currentRoute.value.meta?.showQuickOptions?.currencyConversion === true">
                <Toggle
                  v-model="isUserCurrencyCodeGlobal"
                  is-short
                  class="[&>span>span]:text-gray-200">
                  <template #text>
                    Convertir en {{ convertCurrencyCodeToSymbol(userCurrencyCode) }}                    
                    <Tooltip class="ml-1 align-text-bottom">
                      <template #floating>
                        Le taux de change utilisé est celui du jour de réception de la transaction (dividende, dépôt, etc).<br/>Vous pouvez modifier cette devise principale dans la rubrique 'Mon compte' de la page Paramètres.
                      </template>
                    </Tooltip>
                  </template>
                </Toggle>
              </div>
              <div v-if="router.currentRoute.value.meta?.showQuickOptions?.taxes === true">
                <Toggle
                  v-model="shouldAddUserCountryTax"
                  is-short
                  class="[&>span>span]:text-gray-200">
                  <template #text>
                    Estimer impôts
                    <Tooltip class="ml-1 align-text-bottom">
                      <template #floating>                        
                        Imposition modifiable dans la rubrique 'Mon compte' de la page Paramètres.<br/>
                        Actuellement: {{
                          userTaxCountryCode === 'FR'
                          ? "Taux d'imposition total fixé à 30% (flat tax française), sauf si votre impôt à la source dépasse 15%, auquel cas la France vous demandera 15% d'impôts en plus."
                          : `Le minimum d'impôts locaux est de ${userTaxCustom?.min_country}%, et le minimum d'impôts totaux (source + local) est de ${userTaxCustom?.min_total}%.`
                        }}
                      </template>
                    </Tooltip>
                  </template>
                </Toggle>
              </div>
            </div>
          </nav>
        </div>
        <Button
          class="my-4 mx-2 !px-0 gap-x-2 text-sm font-normal border !border-sky-blue-600 justify-center text-sky-blue-300 !bg-sky-blue-700 hover:!bg-sky-blue-600"
          :class="isSidebarSmall ? '!border-none' : ''"
          data-feedback-fish
          :data-feedback-fish-userid="user?.email || 'unknown'"
          >
          <QuestionMarkCircleIcon class="h-6 w-6" aria-hidden="true" />
          <span :class="isSidebarSmall ? 'hidden' : ''">Question ou problème ?</span>
        </Button>
        <div class="flex-shrink-0 flex border-t border-sky-blue-800 p-4">
          <div @click="navigateTo('/settings/import-data'); isSidebarOpen = false" class="flex-shrink-0 w-full group block cursor-pointer">
            <div class="flex items-center">
              <div>
                <CogIcon class="inline-block h-6 w-6 text-sky-blue-300" />
              </div>
              <div :class="isSidebarSmall ? 'hidden' : ''" class="ml-3">
                <p class="text-sm font-medium text-white">Paramètres</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="isSidebarSmall ? 'md:pl-14' : 'md:pl-64'" class="flex flex-col flex-1 transition-all duration-200">
      <div class="flex items-center sticky w-full box-border top-0 z-10 md:hidden pl-1 sm:pl-3 bg-gray-100">
        <button type="button" class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-blue-500" @click="isSidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <Logo class="inline-flex h-6 w-auto" @click="isSidebarOpen = true" />
      </div>
      <main class="flex-1">
        <div class="py-6 px-4 sm:px-6 md:px-8">
          <!-- Information of the status of the user portfolios -->
          <Alert
            v-if="!$route.path.includes('/settings/import-data') && userAccounts.length === 0"
            type="info"
            class="mb-5"
            >
            Vous n'avez pas encore importé de données. <NuxtLink to="/settings/import-data" class="underline">Cliquez ici pour importer vos données</NuxtLink> depuis Interactive Brokers ou TradeRepublic.
          </Alert>

          <!-- Content of the page -->
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>